import i18next from 'i18next';
import axios from 'axios';

const plugWrapper = document.querySelector('.plug_wrapper');
const notificationPlugWrapper = document.querySelector('.ticker-wrap');
const fullPagePlugText = document.querySelector('.plug_text_second');
const allContent = document.querySelector('.all_content_wrapper');
const tickerItem = document.getElementById('ticker__item');

async function loadData() {
  const API_GET_PLUG = `${process.env.WALLET_URL}v4/plug-service/plugs/landing_ovg?language=${i18next.language}`;

  try {
    const response = await axios.get(API_GET_PLUG);
    const data = await response.data;
    plugWrapper.style.display = 'none';

    if (data && data.enable === false) {
      notificationPlugWrapper.style.display = 'none';
    } else {
      notificationPlugWrapper.style.display = 'flex';

      if (data && data.message) {
        tickerItem.textContent = data.message.message;
      }

      if (data.message.kind === 'full_page') {
        allContent.style.display = 'none';
        plugWrapper.style.display = 'block';
        fullPagePlugText.textContent = data.message.message;
      }
    }
  } catch (error) {
    plugWrapper.style.display = 'none';
    notificationPlugWrapper.style.display = 'none';
  }
}

i18next.on('languageChanged', loadData);

document.addEventListener('DOMContentLoaded', loadData);
